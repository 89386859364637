import React, { useState } from 'react';
import './PortfolioTribute.css';

import data from './data.json';
import backgroundSky from './images/background.jpeg';

const PortfolioTribute = () => {
  const [items, setItems] = React.useState(data.players);

  return (
    <section id="portfolio" className="testimonial-sec"
      style={{
        backgroundImage: 'url(' + backgroundSky + ')',
        WebkitBackgroundSize: 'cover',
        BackgroundSize: '100%',
        backgroundAttachment: 'fixed',
        backgroundPosition: 'center'
      }}
    >
      <div className="container">
        <div className="row" style={{ minHeight: '100vh' }}>
          <div className="col-xl-3 margin-bottom-120 padding-top-30">
            <iframe width="360" height="150" src="https://www.youtube.com/embed/kuuNXxWcmcs?si=AdOmem0IKdmOhRPi?&autoplay=1&loop=1" title="YouTube video player" allow="accelerometer; autoplay; loop; version; playlist; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            <center>
              <a style={{color:'black', fontSize:'25px'}} href='/'>Volver</a>
            </center>
          </div>
          <div className="col-xl-6 margin-bottom-120 padding-top-30">
            <div className="tribute-list">
              <marquee behavior="scroll" scrollamount="2" loop="1" direction="up">
                <center>
                  {
                    items &&
                    items.map((player) => {
                      if (player.alive === "FALSE") {
                        return <div key={player.name} className="col-12">
                          <center style={{ marginTop: '5px' }}>
                            <a target="_blank" rel="noreferrer" href={"https://www.twitch.tv/" + player.twitch}>
                              <h6><b style={{ color: 'black' }} >{player.number} - {player.name}</b></h6>
                            </a>
                          </center>
                        </div>
                      }
                    })}
                </center>
              </marquee>

            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PortfolioTribute;
