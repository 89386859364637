import React, { useState } from 'react';
import './Portfolio.css';
import ReactPaginate from 'react-paginate';

import data from './data.json';
import tvStatic from './images/static.gif';


const Portfolio = () => {
  const [items, setItems] = React.useState(data.players);
  const [currentFilter, setCurrentFilter] = React.useState("Todos");

  const filterPeople = (filter) => {
    setCurrentFilter(filter);
    if (filter === "Todos") {
      setItems(data.players);
    } else if (filter === "Vivos") {
      setItems(data.players);
      setItems((current) =>
        current.filter((player) => player.alive === "TRUE")
      );
    } else if (filter === "Eliminados") {
      setItems(data.players);
      setItems((current) =>
        current.filter((player) => player.alive === "FALSE")
      );
    }
  }

  function Items({ currentItems }) {
    return (
      <>
        {currentItems &&
          currentItems.map((player) => {
            if (player.alive === "TRUE") {
              return <div key={player.name} className="col-xl-2 col-md-3 col-sm-4 col-12">
                <center>
                  <iframe
                    title={player.twitch}
                    src={"https://player.twitch.tv/?channel=" + player.twitch + "&parent=www.squidcraftgames.com&muted=true"}
                    height="<height>"
                    width="<width>"
                    allowFullScreen="False">
                  </iframe>
                  <center>
                    <a target="_blank" rel="noreferrer" href={"https://www.twitch.tv/" + player.twitch}>
                      <h6 style={{ color: 'white' }}><b>{player.number} - {player.name}</b></h6>
                    </a>
                  </center>
                  {
                    player.clip1 !== "" ?
                      <center>
                        <a target="_blank" rel="noreferrer" href={player.clip1} >
                          <h6 style={{ color: 'white' }}><b>Ver Clip</b></h6>
                        </a>
                      </center>
                      :
                      null
                  }

                </center>
              </div>
            } else {
              return <div key={player.name} className="col-xl-2 col-md-3 col-sm-4 col-xs-10 offset-xs-1">

                <div style={{ position: 'relative' }}>
                  <img alt="static" height="155px" src={tvStatic} className="static-image" />
                  {
                    player.clip3 !== "" ?
                      <>
                        <a target="_blank" rel="noreferrer" href={player.clip1} >
                          <h4 className="show-clip-text" style={{ position: "absolute", top: '15%', left: '35%', color: '#FFFFFF', fontWeight: '700' }}>Ver Clip</h4>
                        </a>
                        <a target="_blank" rel="noreferrer" href={player.clip2} >
                          <h4 className="show-clip-text" style={{ position: "absolute", top: '45%', left: '35%', color: '#FFFFFF', fontWeight: '700' }}>Ver Clip</h4>
                        </a>
                        <a target="_blank" rel="noreferrer" href={player.clip3} >
                          <h4 className="show-clip-text" style={{ position: "absolute", top: '75%', left: '35%', color: '#FFFFFF', fontWeight: '700' }}>Ver Clip</h4>
                        </a>
                      </>
                      :
                      player.clip2 !== "" ?
                        <>
                          <a target="_blank" rel="noreferrer" href={player.clip1} >
                            <h4 className="show-clip-text" style={{ position: "absolute", top: '15%', left: '35%', color: '#FFFFFF', fontWeight: '700' }}>Ver Clip</h4>
                          </a>
                          <a target="_blank" rel="noreferrer" href={player.clip2} >
                            <h4 className="show-clip-text" style={{ position: "absolute", top: '45%', left: '35%', color: '#FFFFFF', fontWeight: '700' }}>Ver Clip</h4>
                          </a>
                        </>
                        :
                        player.clip1 !== "" ?
                          <>
                            <a target="_blank" rel="noreferrer" href={player.clip1} >
                              <h4 className="show-clip-text" style={{ position: "absolute", top: '45%', left: '35%', color: '#FFFFFF', fontWeight: '700' }}>Ver Clip</h4>
                            </a>
                          </>
                          : null
                  }
                </div>

                <center style={{ marginTop: '5px' }}>
                  <a target="_blank" rel="noreferrer" href={"https://www.twitch.tv/" + player.twitch}>
                    <del style={{ textDecoration: 'line-through #ff964a' }}>
                      <h6 style={{ color: 'white' }}><b>{player.number} - {player.name}</b></h6>
                    </del>
                  </a>
                </center>
              </div>
            }
          })}
      </>
    );
  }

  function PaginatedItems({ itemsPerPage }) {
    const [itemOffset, setItemOffset] = useState(0);

    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    const currentItems = items.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(items.length / itemsPerPage);

    const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % items.length;
      console.log(
        `User requested page number ${event.selected}, which is offset ${newOffset}`
      );
      setItemOffset(newOffset);
    };

    return (
      <div className="justify-content-center">
        <div className="row justify-content-center">
          <Items currentItems={currentItems} />
        </div>
        <br />
        <br />
        <div className="row justify-content-center pagination-section">
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={1}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
          />
        </div>
      </div>
    );
  }

  return (
    <section id="portfolio" className="testimonial-sec" style={{ backgroundColor: "black" }}>
      <div className="container">
        <div className="row">
          <div className="col-xl-6 offset-xl-3">
            <div className="testimonial-sec-content-wrapper margin-top-20">
              <div className="blue-title-wrapper margin-bottom-20  wow fadeInDown" data-delay="5s">
                <h3 style={{ color: 'white' }}>Players</h3>
              </div>
              <div className="blue-title-wrapper margin-bottom-20  wow fadeInDown" data-delay="5s">
                <h3 style={{ color: 'white' }}>
                  <b className={currentFilter === 'Todos' ? 'filter-link filter-selected' : 'filter-link'} onClick={() => filterPeople('Todos')}>Todos</b> - <b className={currentFilter === 'Vivos' ? 'filter-link filter-selected' : 'filter-link'} onClick={() => filterPeople('Vivos')}>Vivos</b> - <b className={currentFilter === 'Eliminados' ? 'filter-link filter-selected' : 'filter-link'} onClick={() => filterPeople('Eliminados')}>Eliminados</b>
                </h3>
              </div>
            </div>
          </div>
          <div className="col-xl-12 margin-bottom-120 padding-top-30">
            <div className="brand-logo-wrapper ">
              <PaginatedItems itemsPerPage={10} />
            </div>
          </div>
          <div className="col-xl-12">
            <div className="brand-logo-wrapper" style={{ marginTop: '-100px' }}>
              <center>
                <a href="/tribute"><h3>Ver Homenaje a los Caidos</h3></a>
              </center>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Portfolio;
