import React from 'react';
import './Header.css';

import logo from './images/logo.png';

const Header = () => (
  <header>
    <div className="header-bg" style={{ backgroundColor: "black" }}>
      <div className='col-12'>
        <center>
          <img src={logo} />
        </center>
      </div>
      <a className="js-scroll-trigger" href="#domain">
        <div className="scroll-wrapper">
          <div className="big-arrow">
            <div className="line">
            </div>
          </div>
          <p style={{ color: 'white' }}>Scroll</p>
        </div>
      </a>
    </div>
  </header>
);

export default Header;
