import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import './App.css';
import Homepage from './pages/Homepage/Homepage';
import Tribute from './pages/Tribute/Tribute';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/tribute" element={<Tribute />} />
        <Route path="/" element={<Homepage />} />
      </Routes>
    </Router>
  );
}

export default App;
