import React from 'react';
import './Tribute.css';

import PortfolioTribute from '../../components/PortfolioTribute/PortfolioTribute';

const Tribute = () => (
  <div>
    <PortfolioTribute />
  </div>
);

export default Tribute;
